@tailwind base;
@tailwind components;
@tailwind utilities;

/* Personalización global */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.sidebar {
  transition: width 0.3s;
}

.sidebar-expanded {
  width: 16rem;
}

.sidebar-collapsed {
  width: 4rem;
}

.main-content {
  margin-left: 16rem; /* Ajustar según el ancho del sidebar */
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* Sin margen cuando el Sidebar está colapsado */
  }
}

.neon-violet {
  position: relative;
  background-color: black;
  color: white;
  box-shadow: 0 0 5px rgba(138, 43, 226, 0.75), 
              0 0 15px rgba(138, 43, 226, 0.45),
              0 0 25px rgba(138, 43, 226, 0.25),
              0 0 35px rgba(138, 43, 226, 0.1);
}

